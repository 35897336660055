export default {
  "formT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Form"])},
  "formP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate in Sexual and Reproductive Health"])},
  "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
  "prenom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
  "lieu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of Birth"])},
  "nationalite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality"])},
  "pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Country of Residence"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
  "diplome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highest Degree Obtained"])},
  "intitule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title of the Highest Degree"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Briefly describe your relevant professional experience for the program (maximum 300 words)"])},
  "sendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit My Application"])},
  "candidatureT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Submitted"])},
  "candidatureP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your application has been successfully submitted. We will contact you to confirm your pre-selection."])},
  "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit our website"])},
  "buttonbase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply for the Program"])},
  "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Certified in Health Project and Program Management"])},
  "but1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Develop proven expertise in project management in the healthcare sector with our remote professional certification program!"])},
  "objectifT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectives & Targeted Skills"])},
  "objectifP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This training program allows you to develop advanced skills in project management in the healthcare field, to effectively design, plan, execute, evaluate, and adjust public health projects, optimizing resource use and maximizing impact on care quality and population well-being."])},
  "objectifT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health Projects and Programs"])},
  "objectifP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Understand and apply basic project management concepts in the healthcare sector"])},
  "objectifT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strategic Planning"])},
  "objectifP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Develop strategic plans for health projects, considering public health objectives"])},
  "objectifT3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Management Tools"])},
  "objectifP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master specific tools and software for health project management."])},
  "objectifT4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk Management"])},
  "objectifP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identify, assess, and manage potential risks in health projects or programs."])},
  "objectifT5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource Management"])},
  "objectifP5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Develop essential skills in communication, finance, and team management."])},
  "objectifT6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoring and Evaluation"])},
  "objectifP6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design and implement monitoring and evaluation systems to measure the effectiveness of health projects."])},
  "publicsT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target Audience & Prerequisites"])},
  "publicsT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Healthcare Professionals"])},
  "publicsP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctors; surgeons; nurses; midwives; pharmacists; paramedics; health sciences students."])},
  "publicsT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Target Audiences"])},
  "publicsP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NGO or humanitarian organization staff; health project managers, monitoring and evaluation specialists; project coordinators; program managers; independent evaluators; donor staff responsible for leading and evaluating health projects, association leaders, socio-community workers...."])},
  "moyensT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teaching Methods"])},
  "moyensP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This program is based on a competency-based pedagogical approach and fits perfectly into your schedule without disrupting your professional or personal life."])},
  "formatT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
  "formatP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The courses are delivered part-time, exclusively online, in synchronous (virtual classes) and asynchronous (pre-recorded courses) modes."])},
  "comiteT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committee"])},
  "comiteP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The academic committee of this program is composed of internationally recognized experts in health project and program management."])},
  "dureeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
  "dureeP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 hours of lessons"])},
  "periodeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
  "periodeP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From November 1 to December 8, 2024"])},
  "evaluationT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
  "evaluationP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At the end of the program, the learner is subjected to an online knowledge assessment test in MCQ format."])},
  "certificationT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certification"])},
  "certificationP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The program is sanctioned by an accredited international professional certificate in 'Health Project and Program Management,' awarded to learners who obtain a score of 60% or higher in the knowledge assessment."])},
  "contenuT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program Content"])},
  "droitInscriptionT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Fee"])},
  "droitInscriptionTN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Possibility of installment payments*"])},
  "droitInscriptionP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The registration fees for the training are"])},
  "droitInscriptionP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration is only considered final after payment of this amount to the REMA continuing medical education organization, provided that your application is accepted. The deadline for enrolling in the program is October 30, 2024. Limited places!"])},
  "placeDisponible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since the available places for this certification program are limited, only relevant applications will be retained. Selected candidates will be contacted by our teams to finalize their registration."])},
  "produitT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
  "renseignements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
  "entrepriseT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "entrepriseP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
  "entrepriseP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
  "entrepriseP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Career"])},
  "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2022 REMA. All rights reserved."])},
  "confidentialites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])}
}